
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  width: 100%;
  max-width: 496px;
  background: var(--background-dark-primary-elevated);
  border-radius: 16px;
  padding: 31px 32px;
}

.header {
  margin-bottom: 24px;
}

.logo {
  margin-bottom: 30px;
  width: 178px;
  height: auto;
}

.title {
  margin-bottom: 8px;
  @include T1_Regular_32pt;
  color: var(--text-dark-primary);
}

.description {
  @include B1_Regular_17pt;
  color: var(--text-light-secondary);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 44px;
}

.button {
  min-height: 44px;
  background-color: var(--controls-dark-primary-active);
  @include B1_Regular_16pt;
  margin-bottom: 12px;
}

.resetButton {
  width: 100%;
  text-align: center;
  padding-top: 11px;
  padding-bottom: 13px;
}
