
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
}

.dropzone {
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: 1px dashed var(--background-dark-stroke);
  background: var(--background-dark-inputs);
  cursor: pointer;
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;
}

.label {
  @include B2_Regular_14pt;
  color: var(--text-dark-secondary);
  margin-bottom: 8px;
}

.placeholder {
  @include F1_Regular_12pt;
  color: var(--text-dark-tertiary);
  text-align: center;
  margin-bottom: 8px;
}

.button {
  width: 256px;
}

.fileName {
  @include B1_Regular_16pt;
  color: var(--text-dark-primary);
}

.errorText {
  @include F1_Regular_12pt;
  margin-top: 8px;
  display: block;
  color: var(--text-dark-negative);
}

.attachment {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 8px;
  align-items: center;
}

.resetButton {
  cursor: pointer;
}
