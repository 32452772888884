
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
