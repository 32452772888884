
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(18px);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-dark-overlay);
  backdrop-filter: blur(15px);
}

.container {
  position: relative;
  background: var(--background-dark-accordion);
  border-radius: 16px;
  overflow-y: auto;

  @include desktopFullHD {
    border-radius: 20px;
  }
}

.container::-webkit-scrollbar {
  width: 0px;
}

.smallContainer {
  padding: 28px 19px;
}

.defaultContainer {
  padding: 24px;
}

.largeContainer {
  padding: 32px 30px;
}

.extraLargeContainer {
  padding: 40px;
}

.inner {
  min-width: 448px;
  position: relative;
  border-radius: 16px;
  padding: 24px;
  overflow-y: auto;
}

.headerContainer {
  display: grid;
  grid-template-columns: 1fr 32px;
  column-gap: 16px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
}

.title {
  @include T1_Regular_32pt;
  color: var(--text-dark-primary);
  word-break: break-word;

  @include T3_Regular_24pt;

  @include desktopL {
    @include T2_Regular_28pt;
  }

  @include desktopFullHD {
    @include T1_Regular_32pt;
  }
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 0;
  border: none;
  background: var(--controls-dark-tertiary-active);
  border-radius: 50%;
  width: 32px;
  height: 32px;

  & > svg {
    width: 12px;
    height: 12px;

    path {
      fill: var(--text-dark-primary);
    }
  }
}
