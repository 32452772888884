
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  padding: 12px 24px;
  border: 1px solid var(--text-dark-tertiary);
  margin-top: 39px;
  max-width: 400px;
  border-radius: 0 !important;
  opacity: 1 !important;
  z-index: 999;
}

.item {
  cursor: pointer;
}

.content {
  @include B3_Regular_14pt;
  color: var(--text-dark-primary);
  display: flex;
  max-width: 400px;
  white-space: wrap;
}

.arrow {
  display: none;
}
