
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.component {
  padding: 0px 12px;
  text-align: left;
  vertical-align: middle;

  &:first-child {
    padding-left: 0px;
  }

  @include desktopL {
    padding: 0px 24px;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-right: 8px;
}

.entrance {
  border-top: 8px solid var(--accent-dark-positive-inverted);
}

.exit {
  border-bottom: 8px solid var(--controls-dark-primary-active);
}

.entryType {
  color: var(--text-dark-primary);

  @include B2_Regular_12pt_20;

  @include desktopL {
    @include B2_Regular_13pt_20;
  }

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }

  @include desktopUltraHD {
    @include B1_Regular_16pt;
  }
}
