@mixin desktopUltraHD {
  @media (min-width: $desktopUltraHD) {
    @content;
  }
}

@mixin desktopFullHD {
  @media (min-width: $desktopFullHD) {
    @content;
  }
}

@mixin desktopL {
  @media (min-width: $desktopL) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: $laptop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}
