
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.paginator {
  margin-bottom: 0px;
}

.header {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 12px;

  @include desktopFullHD {
    margin-bottom: 14px;
  }
}

.heading {
  @include T3_Regular_18pt;
  color: var(--text-dark-primary);

  @include desktopL {
    @include T3_Regular_21pt;
  }

  @include desktopFullHD {
    @include T3_Regular_24pt;
  }

  @include desktopUltraHD {
    @include T1_Regular_32pt;
  }
}

.table {
  padding-bottom: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
}
