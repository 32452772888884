
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
}

.root input {
  opacity: 0;
  position: absolute;
}

.label {
  @include B3_Regular_14pt;
  margin-left: 8px;
  color: var(--text-dark-primary);
}

.switch {
  position: relative;
  border-radius: 13px;
  background-color: var(--controls-dark-tertiary-active);
  transition: background-color 0.2s ease, border-color 0.2s ease;

  &:after {
    content: '';
    position: absolute;
    top: 7px;
    left: 7px;
    display: block;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background-color: var(--background-dark-primary-elevated);
    box-sizing: border-box;
    transition: transform 0.2s ease;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 12px;
    height: 12px;
    margin: 2px;
    border-radius: 50%;
    background-color: var(--text-dark-primary);
    box-sizing: border-box;
    transition: transform 0.2s ease;
  }

  &Checked {
    background-color: var(--text-dark-negative);

    &:before {
      transform: translateX(12px);
    }

    &:after {
      transform: translateX(12px);
      background-color: var(--text-dark-negative);
    }
  }
}

.small {
  width: 28px;
  height: 16px;
  margin: 2px 0;
}
