
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.app {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: var(--background-dark-primary);
  padding: 0 24px 18px 24px;

  @include laptop {
    padding: 0 28px 20px 28px;
  }

  @include desktop {
    padding: 0 30px 22px 30px;
  }

  @include desktopL {
    padding: 0 36px 24px 36px;
  }

  @include desktopFullHD {
    padding: 0 40px 32px 40px;
  }

  @include desktopUltraHD {
    padding: 0 53px 34px 53px;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.root {
  overflow: hidden;

  @include laptop {
    display: grid;
    grid-template-columns: 1fr 5.44fr;
    column-gap: 18px;
  }

  @include desktop {
    column-gap: 20px;
  }

  @include desktopL {
    column-gap: 24px;
  }

  @include desktopFullHD {
    column-gap: 30px;
  }

  @include desktopUltraHD {
    column-gap: 40px;
  }
}

.sidebar {
  display: none;

  @include laptop {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 116px);
  }
}

.dashboard {
  max-width: 100%;
  height: calc(100vh - 116px);
  width: 100%;
  border-radius: 12px;

  @include desktop {
    border-radius: 14px;
  }

  @include desktopL {
    border-radius: 16px;
  }

  @include desktopFullHD {
    border-radius: 20px;
  }
}

.primary {
  padding: 24px;
  background-color: var(--background-dark-primary-elevated);
}

.secondary {
  padding: 0;
  background-color: var(--background-dark-primary);
}

.content {
  overflow: hidden;
  height: 100%;
}
