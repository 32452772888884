
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--background-dark-primary-elevated);
  row-gap: 4px;
  padding: 16px 38px 18px 20px;
  word-break: break-all;

  @include tablet {
    padding: 24px 38px 18px 20px;
  }

  @include laptop {
    padding: 16px;
    border-radius: 12px;
  }

  @include desktop {
    border-radius: 14px;
  }

  @include desktopL {
    border-radius: 16px;
    padding: 16px 20px;
  }

  @include desktopFullHD {
    border-radius: 20px;
    padding: 24px;
  }

  &Hover {
    cursor: pointer;
  }

  &ActionNotAllowed {
    cursor: not-allowed;
  }

  &WithNavigation {
    padding: 16px 24px 24px 24px;
  }
}

.navigationContainer {
  width: 100%;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--background-dark-divider);
  cursor: pointer;
}

.navigationInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 8px 0;
}

.navigationIcon {
  margin-left: -4px;
  margin-right: 10px;

  > path {
    fill: var(--text-dark-secondary);
  }
}

.navigationLink {
  @include B2_Regular_14pt;
  color: var(--text-dark-secondary);
}

.title {
  @include T5_Bold_18pt;
  color: var(--text-dark-primary);
  margin-bottom: 4px;
  word-break: break-word;

  @include laptop {
    @include S1_Regular_15pt;
    font-weight: 700;
  }

  @include desktop {
    @include S1_Bold_16pt;
  }

  @include desktopFullHD {
    @include T4_Bold_20pt;
  }

  @include desktopUltraHD {
    @include T3_Bold_24pt;
  }
}

.title::-webkit-scrollbar {
  width: 0;
}

.subtitle {
  @include F1_Regular_12pt;
  color: var(--text-dark-secondary);

  @include laptop {
    @include F1_Regular_11pt;
  }
  @include desktopUltraHD {
    @include B2_Regular_14pt;
  }
}
