
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  align-items: center;
}

.input {
  width: 340px;

  @include desktopFullHD {
    width: 408px;
  }
}
