
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  max-height: calc(100vh - 116px);
  border-radius: 20px;
}

.video {
  width: 100%;
  height: 100%;
}

.canvas {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.unrecognizedVisitors {
  position: absolute;
  bottom: 24px;
  left: 24px;
  z-index: 2;
}

.visitor {
  position: absolute;
  top: 64px;
  left: 24px;
  z-index: 2;
}

.visitorInner {
  background-color: var(--background-dark-context-menu-secondary);
}
