
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  color: var(--text-light-primary);
  position: relative;
  display: flex;
  flex-direction: row;

  &:hover {
    cursor: pointer;
  }

  & > span {
    color: var(--text-dark-secondary);
  }

  &:focus {
    outline: none;
    & > span {
      box-shadow: 0 0 0 1px rgba(0, 124, 255, 0.6);
    }
  }
}

.active {
  & > span {
    color: var(--text-dark-primary);
  }

  &::before {
    content: '';
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    border-radius: 50px;
    background-color: var(--controls-dark-primary-active);
  }
}

.linkText {
  @include F1_Regular_12pt;
  white-space: nowrap;

  @include desktopL {
    @include F1_Regular_13pt;
  }

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }

  @include desktopUltraHD {
    @include S1_Regular_16pt;
  }
}

.containerCounter {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--controls-dark-primary-active);
  margin-left: 6px;
}

.counter {
  @include F1_Regular_12pt;
  color: var(--text-dark-primary);
}
