
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.rightAddons {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.areaName {
  color: var(--text-dark-primary);
  @include B4_Regular_14pt;
}
