
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  position: relative;
  z-index: 9999;
}

.inner {
  position: relative;
}
