
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  padding-top: 12px;
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 12px;
  cursor: pointer;
}

.radioButton {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;

  &WithBorder {
    border-bottom: 1px solid var(--background-dark-divider);
  }
}

.contentLast {
  display: flex;
  flex-direction: column;
}

.office {
  @include B2_Regular_14pt;
  color: var(--text-dark-primary);
  margin-bottom: 2px;
}

.address {
  @include F1_Regular_12pt;
  color: var(--text-dark-secondary);
  margin-bottom: 8px;
}
