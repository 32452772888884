
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 16px;
  right: 0;
  overflow: scroll;
  height: 100%;

  @include laptop {
    top: 26px;
  }

  @include desktopL {
    top: 29px;
  }
}

.root::-webkit-scrollbar {
  width: 0px;
}

.filters {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  row-gap: 8px;
  overflow-y: scroll;

  @include desktopFullHD {
    row-gap: 16px;
  }
}

.filters::-webkit-scrollbar {
  width: 0px;
}
