
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.content {
  display: flex;
  align-items: flex-start;
  text-align: left;
  color: var(--text-dark-secondary);
  cursor: pointer;

  @include B2_Regular_12pt_20;

  @include desktopL {
    @include B2_Regular_13pt_20;
  }

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }

  @include desktopUltraHD {
    @include B1_Regular_16pt;
  }
}

.icon {
  margin-left: 4px;
  margin-top: 2px;
  transition: color 0.2s ease;
  flex-shrink: 0;
}
