
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  column-gap: 14px;

  @include laptop {
    grid-template-columns: 3.7fr 1fr;
    column-gap: 18px;
  }

  @include desktop {
    column-gap: 20px;
  }

  @include desktopL {
    column-gap: 20px;
  }

  @include desktopFullHD {
    column-gap: 30px;
  }

  @include desktopUltraHD {
    column-gap: 40px;
  }
}

.dashboard {
  display: grid;
  grid-template-rows: 1fr 50vh;
  row-gap: 14px;

  @include laptop {
    row-gap: 18px;
    grid-template-rows: 1fr 50vh;
  }

  @include desktop {
    row-gap: 20px;
    grid-template-rows: 1fr 50vh;
  }

  @include desktopL {
    row-gap: 20px;
  grid-template-rows: 1fr 30vh;

  }

  @include desktopFullHD {
    row-gap: 30px;
  }

  @include desktopUltraHD {
    row-gap: 40px;
  }
}

.office {
  display: grid;
  grid-template-columns: 1fr 2.8fr;
  column-gap: 14px;

  @include laptop {
    column-gap: 18px;
  }

  @include desktop {
    column-gap: 20px;
  }

  @include desktopL {
    column-gap: 20px;
  }

  @include desktopFullHD {
    column-gap: 30px;
  }

  @include desktopUltraHD {
    column-gap: 40px;
  }
}

.journal {
  max-height: 100%;
  padding: 16px 0 16px 20px;
  background-color: var(--background-dark-primary-elevated);
  border-radius: 12px;

  @include laptop {
    border-radius: 14px;
  }

  @include desktopL {
    border-radius: 16px;
    padding: 20px 0 0 20px;
  }

  @include desktopFullHD {
    padding: 24px 0 0 24px;
    border-radius: 20px;
  }

  @include desktopUltraHD {
    padding: 28px 0 28px 32px;
  }
}

.stream {
  width: 100%;
  height: 100%;
}

.sidebar {
  display: none;
  height: 100%;

  @include laptop {
    display: flex;
    overflow-y: scroll;
  }
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-dark-primary-elevated);
  border-radius: 12px;

  @include desktop {
    border-radius: 14px;
  }

  @include desktopL {
    border-radius: 16px;
  }

  @include desktopUltraHD {
    border-radius: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 352px;
  row-gap: 12px;
}

.button {
  max-width: 195px;

  @include desktopUltraHD {
    margin-top: 8px
  }
}

.text {
  @include F1_Regular_13pt;
  text-align: center;
  color: var(--text-dark-primary);

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
