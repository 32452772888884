
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  padding: 10px 18px 0 16px;
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 8px;
  cursor: pointer;
  align-items: flex-start;

  @include laptop {
    padding: 10px 16px 0 12px;
  }

  @include desktop {
    grid-template-columns: 24px 1fr;
  }

  @include desktopL {
    padding: 12px 20px 0 16px;
  }

  @include desktopFullHD {
    column-gap: 12px;
  }
}

.radioButton {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;

  @include desktopL {
    width: 24px;
    height: 24px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  &WithBorder {
    border-bottom: 1px solid var(--background-dark-divider);
  }
}

.contentLast {
  display: flex;
  flex-direction: column;
}

.area {
  @include F1_Regular_12pt;
  color: var(--text-dark-primary);
  margin-bottom: 2px;
  word-break: break-all;
  max-width: 180px;

  @include desktop {
    @include B4_Regular_14pt;
  }

  @include desktopUltraHD {
    @include T4_Regular_18pt;
  }
}

.visitors {
  @include F2_Regular_12pt;
  color: var(--text-dark-secondary);
  margin-bottom: 8px;

  @include laptop {
    @include F2_Regular_12pt;
    font-size: 11px;
  }

  @include desktop {
    @include F2_Regular_12pt;
  }

  @include desktopUltraHD {
    @include B2_Regular_14pt;
  }
}
