
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  width: 100%;
  padding: 20px 16px 16px 16px;

  @include desktopL {
    padding: 20px 0;
  }

  @include desktopFullHD {
    padding: 24px 0;
  }
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: max-content 1fr 1fr;
  align-items: center;
  column-gap: 12px;

  @include laptop {
    grid-template-columns: minmax(194px, 1fr) 1fr 1fr;
  }
}

.logo {
  margin-right: 10px;
  width: 178px;
  height: auto;

  @include desktopL {
    margin-right: 0px;
  }

  @include desktopUltraHD {
    transform: scale(1.1875);
    margin-left: 33px;
  }
}

.nav {
  display: flex;
  justify-content: left;
  align-items: center;

  @include laptop {
    justify-content: center;
  }
}

.link {
  &:not(:last-of-type) {
    margin-right: 16px;
  }
}

.navLink {
  @include F1_Regular_12pt;
  color: var(--text-dark-primary);
  margin-right: 4px;
}

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 16px;
  justify-content: flex-end;

  @include desktopL {
    column-gap: 24px;
  }
}

.notification {
  position: relative;
  margin-left: 8px;

  @include desktopL {
    margin-left: 0px;
  }
}

.counter {
  position: absolute;
  background-color: var(--controls-dark-primary-active);
  bottom: -5px;
  right: -5px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number {
  @include F2_Regular_10pt;
  margin-right: 1px;
  margin-bottom: 1px;
  color: var(--text-dark-primary);
}

.dropdownMenu {
  position: relative;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.mobileMenu {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.menu {
  position: absolute;
  top: 36px;
  border-radius: 8px;
  background-color: var(--background-dark-accordion);
  display: flex;
  flex-direction: column;
  z-index: 9999;
  padding: 12px 16px;
  row-gap: 10px;
}

.navLinkIcon {
  rotate: 180deg;
}

.anchor {
  span {
    @include F1_Regular_12pt;
    color: var(--text-dark-primary);
  }

  &::before {
    content: '';
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    border-radius: 50px;
    background-color: var(--background-dark-divider);
  }
}

.anchorLast {
  &::before {
    height: 0;
  }
}

.sidebar {
  display: flex;
  overflow-y: scroll;
  height: 100%;

  @include laptop {
    display: none;
  }
}
