
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  margin: 24px auto;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  margin-bottom: 10px;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 9px;
  justify-content: center;
}

.button {
  border-radius: 50%;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #303133;
  }

  &.play {
    margin-left: -1px;
  }
}

.name {
  @include S1_Bold_16pt;
  color: var(--text-dark-primary);
  display: flex;
  align-items: center;
}

.info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.live {
  @include B2_Regular_14pt;
  color: var(--text-dark-primary);
  padding: 2px 8px;
  background-color: var(--controls-dark-primary-active);
  border-radius: 4px;
  margin-right: 12px;
}

.date {
  @include B2_Regular_14pt;
  color: var(--text-dark-primary);
  width: 134px;
  text-align: right;
  white-space: nowrap;
}
