
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--background-dark-overlay);
  backdrop-filter: blur(10px);
  width: 100%;
  max-width: 347px;
}

.header {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.icon {
  flex-shrink: 0;
}

.titleWrap {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.title {
  @include S1_Bold_16pt;
  color: var(--text-dark-primary);
}

.time {
  @include F1_Regular_12pt;
  color: var(--text-dark-secondary);
}

.closeBtn {
  margin-left: auto;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background-color: var(--controls-dark-tertiary-active);
}

.closeIcon {
  path {
    fill: var(--text-dark-primary);
  }
}

.description {
  padding-left: 36px;
  @include B2_Regular_14pt;
  color: var(--text-dark-primary);
}
