
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  position: absolute;
  visibility: visible;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 101;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
}

.user {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 190px;
  padding: 14px;
  background-color: var(--background-dark-overlay-secondary);
  border-radius: 16px;
  backdrop-filter: blur(10px);

  @include desktop {
    padding: 16px;
    width: 247px;
  }
}

.avatar {
  width: 158px;
  height: 158px;
  overflow: hidden;
  margin-bottom: 16px;
  border-radius: 8px;

  @include desktop {
    width: 215px;
    height: 215px;
  }
}

.heading {
  display: grid;
  grid-template-columns: 16px 1fr;
  column-gap: 8px;
  margin-bottom: 4px;

  > svg {
    width: 16px;
    height: 20px;
  }

  &GuestIcon {
    *g {
      stroke: var(--icons-dark-tertiary);
    }
  }

  &VisionIcon {
    padding-top: 2px;
  }

  &TargetIcon {
    padding-top: 2px;

    g {
      stroke: var(--controls-dark-primary-active);
    }
  }
}

.divider {
  width: 100%;
  border: 1px solid var(--background-dark-divider);
  margin: 8px 0;
}

.host {
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  &Avatar {
    width: 40px;
    height: 40px;

    img {
      border-radius: 50%;
    }
  }

  &Info {
    display: flex;
    flex-direction: column;
  }

  &Heading {
    @include F1_Regular_12pt;
    color: var(--text-dark-secondary);
  }

  &Fullname {
    @include S1_Bold_14pt;
    color: var(--text-dark-primary);
  }
}

.info {
  display: flex;
  flex-direction: column;
}

.fullname {
  @include S1_Bold_16pt;
  color: var(--text-dark-primary);
}

.company {
  @include F1_Regular_12pt;
  margin-left: 24px;
  color: var(--text-dark-secondary);
  margin-bottom: 2px;
}

.accessFrom {
  @include F1_Regular_12pt;
  margin-left: 24px;
  color: var(--text-dark-secondary);
}

.button {
  margin-top: 20px;
}

.buttonClose {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  visibility: hidden;
  > svg > path {
    fill: var(--text-dark-primary);
  }
}

.buttonCloseVisible {
  visibility: visible;
}

.buttonCloseAnimationIn {
  animation: translafeFromLeft 0.2s linear;
}

.buttonCloseAnimationOut {
  animation: translafeFromRight 0.2s linear;
}

.buttonContainer {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: -37px;
}

@keyframes translafeFromLeft {
  0% {
    animation-timing-function: linear;
    transform: translateX(-30px);
  }

  100% {
    animation-timing-function: linear;
    transform: translateX(0px);
  }
}

@keyframes translafeFromRight {
  0% {
    animation-timing-function: linear;
    transform: translateX(0px);
  }

  100% {
    animation-timing-function: linear;
    transform: translateX(-30px);
  }
}
