
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  position: relative;
  display: flex;
  align-items: center;
}

.hiddenInput {
  position: absolute !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip-path: inset(100%) !important;
  clip: rect(0 0 0 0) !important;
  overflow: hidden !important;

  &:enabled + span {
    &:hover {
      border-color: var(--accent-dark-negative);
    }
  }

  &:checked + span {
    background: var(--controls-dark-primary-active);
    border: none;

    &:hover {
      background: var(--controls-dark-primary-light) !important;
    }

    & svg {
      display: flex;
    }
  }
}

.label {
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & svg {
    display: none;
  }
}

.checkboxIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--controls-dark-tertiary-active);
  border: 1.5px solid var(--controls-dark-tertiary-active);
  border-radius: 6px;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
