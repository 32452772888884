
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  width: 110px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 13px 13px 9px 13px;
  background-color: var(--background-dark-filter);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  cursor: pointer;
  z-index: 101;

  @include desktopFullHD {
    width: 140px;
    height: 110px;
    border-radius: 16px;
    padding: 16px 16px 12px 16px;
  }

  &:hover {
    background-color: var(--background-dark-contex-hover);
  }

  &Employee {
    background-color: var(--accent-dark-marker-background-green);

    &:hover {
      background-color: var(--accent-dark-marker-background-green);
    }
  }

  &Guest {
    background-color: var(--accent-dark-marker-background-yellow);

    &:hover {
      background-color: var(--accent-dark-marker-background-yellow);
    }
  }

  &Contractor {
    background-color: var(--accent-dark-marker-background-pink);

    &:hover {
      background-color: var(--accent-dark-marker-background-pink);
    }
  }

  &Unidentified {
    background-color: var(--accent-dark-marker-background-red);

    &:hover {
      background-color: var(--accent-dark-marker-background-red);
    }
  }

  &Security {
    background-color: var(--accent-dark-marker-background-blue);

    &:hover {
      background-color: var(--accent-dark-marker-background-blue);
    }
  }
}

.marker {
  margin-bottom: 10px;

  @include desktopFullHD {
    margin-bottom: 20px;
  }
}

.employee {
  circle {
    fill: var(--accent-dark-positive-inverted);
  }
}

.guest {
  circle {
    fill: var(--accent-dark-marker-yellow);
  }
}

.contractor {
  circle {
    fill: var(--accent-dark-marker-pink);
  }
}

.unrecognized {
  circle {
    fill: var(--accent-dark-negative-inverted);
  }
}

.security {
  circle {
    fill: var(--accent-dark-active-inverted);
  }
}

.groupname {
  @include B2_Regular_12pt;
  color: var(--text-dark-secondary);

  &Active {
    color: var(--text-dark-primary);
  }

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }
}

.visitors {
  @include F4_Bold_16pt;
  color: var(--text-dark-primary);

  @include desktopFullHD {
    @include T4_Bold_20pt;
  }
}
