
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  margin: 10px 0;
  padding: 14px 16px 8px 16px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  width: 203px;

  @include desktop {
    border-radius: 12px;
  }

  @include desktopL {
    width: 221px;
    border-radius: 14px;
  }

  @include desktopFullHD {
    width: 273px;
    border-radius: 16px;
  }
}

.primary {
  background: var(--background-dark-context-menu);
}

.secondary {
  background: var(--background-context-menu-secondary);
}

.menu {
  display: flex;
  flex-direction: column;
}

.button {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: flex-start;
}

.icon {
  @include desktopFullHD {
    margin-top: 3px;
  }

  svg {
    transform: scale(0.88);
  }

  @include desktopL {
    svg {
      transform: scale(1);
    }
  }
}

.title {
  @include F1_Regular_12pt_14;
  color: var(--text-dark-primary);
  padding-bottom: 8px;

  @include desktopL {
    @include F1_Regular_13pt;
  }

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid var(--background-dark-divider);
  margin-bottom: 6px;

  &Last {
    margin-bottom: 0;
    border-bottom: none;
  }
}

.iconLink {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
