
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  overflow: hidden;
  text-align: center;
}

.icon {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
}

.title {
  margin-bottom: 8px;
  color: var(--text-dark-primary);
  @include T3_Regular_24pt;
}

.description {
  margin-bottom: 24px;
  color: var(--text-dark-secondary);
  @include S1_Regular_16pt;
}

.button {
  color: var(--text-dark-primary);
  @include S1_Regular_16pt;
  cursor: pointer;
}

.closeButton {
  margin-bottom: 12px;
}

.writeToTheDevelopersButton {
  color: var(--text-dark-primary);
  padding-top: 11px;
  padding-bottom: 13px;
  @include S1_Regular_16pt;
}
