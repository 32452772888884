
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.track {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  flex-wrap: nowrap;
  overflow-x: scroll;
  direction: rtl; // INFO: хак для реверса таймлайна
}

.track::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}

.tick {
  display: flex;
  column-gap: 8px;
}

.tick_container {
  width: 32px;
  height: 32px;
}

.tick_inner {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.tick_primary {
  width: 1px;
  height: 14px;
  background: var(--timeline-tick);
}

.tick_secondary {
  width: 1px;
  height: 7px;
  background: var(--timeline-tick);
}

.tick_time {
  @include F1_Regular_12pt;
  color: var(--text-dark-primary);
  text-align: center;
}

.slider {
  width: 100%;
  display: flex;
  justify-content: center;
}

.rightEdge {
  margin-left: -8px;
}
