
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
}

.label {
  @include B2_Medium_14pt;
  color: var(--text-dark-secondary);
  margin-right: 2px;
}

.hint {
  @include F1_Regular_12pt;
  color: var(--text-dark-secondary);
  margin-top: 4px;
}

.textarea {
  @include B3_Regular_14pt;
  color: var(--text-dark-primary);
  border-radius: 6px;
  border: 1px solid var(--controls-dark-tertiary-active);
  background-color: var(--background-dark-inputs);
  padding: 10px 12px;
  width: 100%;
  max-width: 400px;
  height: 80px;
  margin-top: 4px;
  resize: none;

  &:focus {
    outline: none;
    border-color: var(--accent-dark-active-inverted);
  }
}

.textarea::-webkit-scrollbar {
  width: 14px;
}

.textarea::-webkit-scrollbar-thumb {
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: var(--controls-dark-inactive-tabbar);
}

.textarea::-webkit-scrollbar-corner {
  background: transparent;
}

.error {
  @include F1_Regular_12pt;
  margin-top: 4px;
  color: var(--accent-dark-negative-inverted);
  max-width: 380px;
  word-break: break-all;
}

.errorBorder {
  border-color: var(--accent-dark-negative-inverted);
}

.errorColor {
  color: var(--accent-dark-negative-inverted);
}
