
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.circularProgressBar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #ddd;
    border-top-color: #000;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .customToast {
    display: flex;
    align-items: center;
  }

  .customToastMessage {
    margin-left: 8px;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    user-select: none;
    font-family: "Aeroport", sans-serif;
  }

  .customToastContainer {
    padding: 12px;
    background-color: #0D0E0FE5;
    bottom: 8px;
    right: -39px;
    min-height: 46px;
    max-width: 255px;
  }

  .customToastBody {
    margin: 0;
    padding: 0;
  }

  .customToastClose {
    margin-left: auto;
  }

  .customProgressBar {
    background: #000;
  }

  .customToastPosition{
    bottom: 24px;
    right: 34px;
    max-width: 255px;

  }

  @property --progress-value {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
  }
  
  @keyframes progress {
   to { --progress-value: 100; }
  }
  
  .progressBar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: 
      radial-gradient(closest-side, #0e0f10 88%, transparent 80% 100%),
      conic-gradient(#676973 calc(var(--progress-value) * 1%), rgb(103 105 115 / 20%) 0);
    animation: progress 9s 1 forwards;
  }
