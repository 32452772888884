
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  transition: all 0.1s;

  cursor: pointer;
}

.primary {
  background: var(--text-dark-inverted);
  opacity: 0.75;
}

.secondary {
  background: var(--background-dark-stroke);
  opacity: 1;

  &:hover {
    background-color: var(--controls-dark-tertiary-light);
    backdrop-filter: blur(10px);
  }

  &:active {
    background-color: var(--background-button-active);
  }
}

.negative {
  background-color: var(--text-dark-negative);
}

.default {
  border-radius: 32px;
  backdrop-filter: blur(10px);
}

.up {
  border-radius: 32px 32px 0 0;
}

.down {
  border-radius: 0 0 32px 32px;
}
