
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.label {
  @include B2_Regular_14pt;
  color: var(--text-dark-primary);
}

.root input {
  opacity: 0;
  position: absolute;
}

.group {
  width: 154px;
  height: 32px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.select {
  @include B2_Medium_14pt;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 77px;
  height: 32px;
  color: var(--text-dark-primary);
  background-color: var(--controls-dark-tertiary-active);
}

.checked {
  background-color: var(--controls-dark-primary-active);
}

.right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
