
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
}

.container {
  padding-top: 4px;
  display: flex;
  flex-direction: column;
}

.content {
  @include S1_Regular_16pt;
  color: var(--text-dark-secondary);
  margin-bottom: 16px;
}

.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
}

.emergencyTextLabel {
  font-family: 'Aeroport', sans-serif;
  color: #90919c;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
}

.emergencyTextarea {
  font-family: 'Aeroport', sans-serif;
  border: 1px solid #393e42;
  color: #fff;
  padding: 10px 12px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  resize: none;
  margin-bottom: 32px;
  height: 80px;
}
