
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  width: 100%;
  height: calc(100vh - 258px);
  background-color: var(--background-dark-primary-elevated);
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--background-dark-divider);
}
