
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  max-width: 390px;

  @include desktopL {
    max-width: 450px;
  }
}

.heading {
  @include S1_Regular_16pt;
  color: var(--text-dark-secondary);
  margin-bottom: 32px;
}

.input {
  margin-bottom: 24px;
}

.dropzone {
  margin-bottom: 44px;
}
