
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  height: 100%;
  max-height: calc(100vh - 225px);
  flex-direction: column;
  align-items: center;
}

.start {
  justify-content: space-between;
}

.center {
  justify-content: center;
}

.emptyHeading {
  @include S1_Regular_16pt;
  color: var(--text-dark-primary);
  margin-top: 4px;
}

.iconSmall {
  max-width: 75px;
}
.iconSmall svg {
  width: 100%;
  height: 80px;
}
