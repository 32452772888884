
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.canvas {
  width: 100%;
  height: 100%;
}

.controls {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  row-gap: 0;
  border-radius: 32px;
  backdrop-filter: blur(10px);
}

.rotateLeft {
  svg {
    transform: rotate(45deg);
  }
}

.rotateRight {
  svg {
    transform: rotate(-45deg);
  }
}

.scaleFactor {
  @include B3_Regular_14pt;
  padding: 4px 0;
  color: var(--text-dark-primary);
  text-align: center;
  background: var(--background-dark-stroke);
  width: 30px;

  @include desktopL {
    width: 32px;
  }

  @include desktopUltraHD {
    width: 44px;
  }
}

.contextMenu {
  margin-left: 14px;
  max-width: 230px;
}
