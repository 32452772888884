
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
:root {
  --text-dark-primary: #ffffff;
  --text-dark-secondary: #90919c;
  --text-dark-tertiary: #696b77;
  --text-dark-inverted: #000000;
  --text-dark-positive: #63c966;
  --text-dark-positive-pass: #54ab57;
  --text-dark-negative: #ff0014;
  --text-light-secondary: #626c77;

  --background-dark-primary: #0d0e0f;
  --background-dark-primary-elevated: #191a1c;
  --background-dark-stroke: rgba(103, 105, 115, 0.35);
  --background-dark-inputs: #26282b;
  --background-dark-overlay: rgba(0, 0, 0, 0.6);
  --background-dark-overlay-secondary: rgba(255, 255, 255, 0.07);
  --background-dark-divider: rgba(103, 105, 115, 0.2);
  --background-dark-filter: rgba(255, 255, 255, 0.05);
  --background-dark-context-menu: rgba(13, 12, 12, 0.75);
  --background-dark-context-menu-secondary: rgba(13, 12, 12, 0.65);
  --background-dark-accordion: #202124;
  --background-dark-contex-hover: #2b2c2d;
  --background-button-active: rgba(138, 140, 153, 0.1);
  --background-context-menu-secondary: rgba(255, 255, 255, 0.08);
  --background-profile: #2b2c2e;
  --background-table-row: #202123;

  --icons-dark-tertiary: #6b6d77;
  --timeline-tick: #a1a1a1;

  --controls-dark-primary-light: #ff404f;
  --controls-dark-primary-active: #ff0014;
  --controls-dark-primary-dark: #cc1414;
  --controls-dark-tertiary-light: rgba(138, 140, 153, 0.45);
  --controls-dark-tertiary-active: rgba(138, 140, 153, 0.25);
  --controls-dark-tertiary-dark: rgba(138, 140, 153, 0.1);
  --controls-dark-inactive: rgba(138, 140, 153, 0.2);
  --controls-dark-alternative: rgba(138, 140, 153, 0.35);
  --controls-dark-active-tabbar: #ffffff;
  --controls-dark-inactive-tabbar: #60626b;

  --accent-dark-active: #45b6fc;
  --accent-dark-positive: #77c979;
  --accent-dark-warning: #fad67d;
  --accent-dark-negative: #ff5b68;
  --accent-dark-active-inverted: #007cff;
  --accent-dark-positive-inverted: #63c966;
  --accent-dark-warning-inverted: #fac031;
  --accent-dark-negative-inverted: #ff0014;
  --accent-dark-marker-yellow: #ffdf34;
  --accent-dark-marker-pink: #c446c7;

  --accent-dark-marker-background-green: rgba(49, 229, 54, 0.3);
  --accent-dark-marker-background-yellow: rgba(255, 223, 52, 0.5);
  --accent-dark-marker-background-pink: rgba(196, 70, 199, 0.45);
  --accent-dark-marker-background-red: rgba(255, 0, 20, 0.4);
  --accent-dark-marker-background-blue: rgba(0, 124, 255, 0.39);
}
