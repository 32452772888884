
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  overflow: hidden;
}

.list {
  margin-bottom: 32px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.modalContainer {
  overflow: hidden;
}
