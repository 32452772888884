
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 12px;
  }
}

.cameraInfo {
  grid-template-columns: 7px 1fr;
  column-gap: 8px;
  position: relative;
  display: flex;
  align-items: center;
}

.cameraImg {
  width: 265px;
  height: 149px;
  border-radius: 8px;
  object-fit: cover;
}

.modalWrap {
  padding: 32px;
}

.cameraIndicator {
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: var(--accent-dark-positive-inverted);
  &Inactive {
    background-color: var(--accent-dark-negative-inverted);
  }
}

.cameraName {
  @include B2_Regular_14pt;
  color: var(--text-dark-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
