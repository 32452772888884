
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  max-width: 416px;
  margin-left: auto;

  @include tablet {
    gap: 16px;
  }
}

.activeLinkClassName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: default;
  background-color: var(--controls-dark-tertiary-active);
  border-radius: 50%;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08);
}

.breakLinkClassName {
  @include B1_Regular_16pt;
  color: var(--text-dark-primary);
  cursor: pointer;
}

.pageClassName {
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    cursor: pointer;

    @include tablet {
      width: 24px;
      height: 24px;
    }
  }
}

.pageLinkClassName {
  @include B1_Regular_16pt;
  color: var(--text-dark-primary);

  @include tablet {
    font-size: 14px;
  }
}

.previousClassName {
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}

.nextClassName {
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
}
