
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.component {
  padding: 0px 12px;
  text-align: left;
  vertical-align: middle;

  &:first-child {
    padding-left: 0px;
  }

  @include desktopL {
    padding: 0px 24px;
  }
}

.children {
  color: var(--text-dark-primary);
  white-space: nowrap;

  @include B2_Regular_12pt_20;

  @include desktopL {
    @include B2_Regular_13pt_20;
  }

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }

  @include desktopUltraHD {
    @include B1_Regular_16pt;
  }
}
