
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: var(--background-dark-primary-elevated);
  height: 100%;
  overflow: hidden;
  border-radius: 12px;

  @include tablet {
    border-radius: 0;
  }

  @include desktop {
    border-radius: 14px;
  }

  @include desktopL {
    border-radius: 16px;
  }

  &EmptyHead {
    padding-top: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-bottom: 120px;

  &Empty {
    height: 100%;
  }
}

.content::-webkit-scrollbar {
  width: 0px;
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  border-radius: 20px 20px 0 0;

  &HasBackOption {
    height: calc(100vh - 405px);
  }

  &Empty {
    @include S1_Regular_16pt;
    color: var(--text-light-secondary);
    text-align: center;
    padding: 0 34px;
    justify-content: center;
    height: 100%;
  }

  &EmptyHead {
    border-radius: 0;
  }
}

.list::-webkit-scrollbar {
  width: 0px;
}

.title {
  @include S2_Bold_16pt;
  color: var(--text-dark-primary);
  padding: 0 18px;
  margin-bottom: 4px;

  @include laptop {
    padding: 16px 16px 0;
  }

  @include desktop {
    @include S2_Bold_18pt;
    padding: 20px 24px 0;
    margin-bottom: 8px;
  }

  @include desktopL {
    @include T4_Bold_20pt;
    margin-bottom: 10px;
  }

  @include desktopFullHD {
    padding: 24px 24px 0;
    margin-bottom: 10px;
  }
  @include desktopUltraHD {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 28px 20px;

  @include laptop {
    padding: 20px 16px;
  }

  @include desktopL {
    padding: 20px;
  }

  @include desktopFullHD {
    padding: 20px 24px;
  }

  > button > svg {
    transform: rotate(45deg);
    margin-right: 5px;

    > path {
      fill: var(--text-dark-primary);
    }
  }
}
