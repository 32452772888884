
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  z-index: 10;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leftIcons {
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  @include desktopFullHD {
    column-gap: 16px;
  }
}

.arrowRight {
  g {
    stroke: var(--text-dark-primary);
  }
}
