
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.component {
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  border-collapse: collapse;
}

.hasTopBorder {
  border-top: 1px solid var(--background-dark-divider);
}

.component::-webkit-scrollbar {
  visibility: hidden;
  background-color: transparent;
  width: 4px;
}

.component::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.component:hover::-webkit-scrollbar {
  visibility: initial;
  background-color: transparent;
}

.component:hover::-webkit-scrollbar-thumb {
  visibility: initial;
  background-color: #60626b;
  border-radius: 10px;
}

.table {
  width: 100%;
}

.paginator {
  margin-top: 16px;
  width: 100%;

  @include desktop {
    margin-top: 32px;
  }

  @include tablet {
    margin-top: 18px;
  }
}

.primary {
  background-color: var(--background-dark-primary-elevated);
}

.secondary {
  background-color: var(--background-dark-accordion);
}
