
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
    display: flex;
    flex-direction: column;
  }
  
  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 4px;
    height: 100vh;
  }

  .title{
    @include T2_Regular_24pt;
    color: #ffffff;
    margin-top: 16px
  }

  .subtitle{
    @include B2_Regular_14pt;
    color: #696B77;
  }