/* stylelint-disable scale-unlimited/declaration-strict-value */

@mixin T1_Bold_32pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
}

@mixin T1_Regular_32pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
}

@mixin T2_Regular_24pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}

@mixin T2_Regular_28pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
}

@mixin T2_Bold_28pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}

@mixin T3_Regular_21pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 28px;
}

@mixin T3_Regular_24pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

@mixin T3_Bold_24pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

@mixin T3_Regular_20pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
}

@mixin T3_Regular_18pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

@mixin T4_Regular_18pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

@mixin T4_Bold_16pt {
  font-family: 'Aeroport', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

@mixin T4_Bold_18pt {
  font-family: 'Aeroport', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

@mixin T5_Bold_18pt {
  font-family: 'Aeroport', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

@mixin T4_Bold_20pt {
  font-family: 'Aeroport', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

@mixin S1_Bold_14pt {
  font-family: 'Aeroport', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

@mixin S1_Regular_16pt {
  font-family: 'Aeroport', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

@mixin S1_Regular_15pt {
  font-family: 'Aeroport', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

@mixin S1_Bold_16pt {
  font-family: 'Aeroport', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

@mixin S1_Bold_15pt {
  font-family: 'Aeroport', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

@mixin S2_Regular_18pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin B1_Regular_17pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
}

@mixin S2_Regular_16pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

@mixin S2_Bold_16pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

@mixin S2_Bold_18pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

@mixin B1_Regular_16pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin B2_Medium_14pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

@mixin B2_Regular_14pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin B2_Regular_13pt_20 {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

@mixin B2_Regular_12pt_20 {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

@mixin B2_Regular_12pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin B3_Regular_14pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin B4_Regular_14pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

@mixin F1_Regular_12pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin F1_Regular_12pt_14 {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

@mixin F1_Regular_13pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

@mixin F1_Regular_11pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
}

@mixin F2_Regular_10pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

@mixin F2_Medium_10pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

@mixin F2_Regular_12pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

@mixin F3_Regular_12pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

@mixin F3_Regular_10pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}

@mixin F4_Regular_13pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

@mixin F4_Bold_16pt {
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}
