
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: grid;
  grid-template-columns: 383px 1fr;
  column-gap: 48px;
  height: 100%;
  max-height: calc(100vh - 112px);
}

.content {
  display: flex;
  flex-direction: column;
}

.sidebar {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.avatar {
  margin-bottom: 24px;
}

.pass {
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  align-items: center;
  justify-content: flex-start;
  column-gap: 6px;
  border-radius: 6px;
  width: fit-content;
  background-color: var(--text-dark-positive-pass);
  margin-bottom: 36px;

  &_text {
    @include B2_Regular_14pt;
    color: var(--text-dark-primary);
  }
}

.image {
  width: 383px;
  height: 383px;
  object-fit: cover;
  border-radius: 16px;
}

.fullname {
  @include T1_Bold_32pt;
  color: var(--text-dark-primary);
  margin-bottom: 16px;
}

.info {
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
  row-gap: 8px;

  &_row {
    display: grid;
    grid-template-columns: 120px 1fr;
    flex-wrap: nowrap;
  }

  &_name {
    @include B3_Regular_14pt;
    color: var(--text-dark-secondary);
  }

  &_data {
    @include B3_Regular_14pt;
    color: var(--text-dark-primary);
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.paginator {
  margin-bottom: 0;
}

.table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 40px;
}

.loading {
  height: calc(100vh - 240px);
  display: flex;
  align-items: center;
  justify-content: center;
}
