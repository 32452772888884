
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.form {
  width: 400px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
