
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  background-color: var(--background-dark-primary-elevated);
  border-radius: 12px;

  @include laptop {
    border-radius: 14px;
  }

  @include desktop {
    border-radius: 16px;
  }

  @include desktopFullHD {
    border-radius: 20px;
  }
}

.header {
  padding: 16px 20px 0 20px;

  @include desktopL {
    padding: 20px 20px 0 20px;
  }

  @include desktopFullHD {
    padding: 24px 24px 0 24px;
  }

  @include desktopUltraHD {
    padding: 32px 24px 0 24px;
  }
}

.heading {
  margin-bottom: 12px;

  a {
    @include T3_Regular_18pt;
    color: var(--text-dark-primary);

    @include desktopL {
      @include T3_Regular_21pt;
    }

    @include desktopFullHD {
      @include T3_Regular_24pt;
    }

    @include desktopUltraHD {
      @include T1_Regular_32pt;
      margin-bottom: 18px;
    }
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  column-gap: 18px;
  border-bottom: 1px solid var(--background-dark-divider);
  padding-bottom: 9px;
}

.alerts {
  height: 100%;
  max-height: calc(100vh - 140px);
  margin-bottom: 20px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.alerts::-webkit-scrollbar {
  visibility: hidden;
  background-color: transparent;
  width: 4px;
}

.alerts::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.alerts:hover::-webkit-scrollbar {
  visibility: initial;
  background-color: transparent;
}

.alerts:hover::-webkit-scrollbar-thumb {
  visibility: initial;
  background-color: #60626b;
  border-radius: 10px;
}
