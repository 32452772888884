
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include laptop {
    row-gap: 8px;
  }
  @include desktopL {
    row-gap: 10px;
  }

  &Empty {
    height: 100%;
  }
}
