
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-dark-primary-elevated);
  position: relative;
  overflow: hidden;
  border-radius: 12px;

  @include desktop {
    border-radius: 14px;
  }

  @include desktopL {
    border-radius: 16px;
  }

  @include desktopUltraHD {
    border-radius: 20px;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 24px;
  background: var(--background-dark-context-menu);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 41px;
  justify-content: space-between;
  align-items: center;
}

.streamName {
  @include S1_Bold_16pt;
  color: var(--text-dark-primary);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include laptop {
    font-size: 14px;
  }
}

.streamIndicator {
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: var(--accent-dark-positive-inverted);
  margin-top: 6px;
  margin-right: 8px;

  &Inactive {
    background-color: var(--accent-dark-negative-inverted);
  }
}

.status {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.statusText {
  @include B2_Regular_14pt;
  color: var(--text-dark-primary);

  @include laptop {
    font-size: 12px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-dark-primary-elevated);
  border-radius: 12px;

  @include desktop {
    border-radius: 14px;
  }

  @include desktopL {
    border-radius: 16px;
  }

  @include desktopUltraHD {
    border-radius: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 352px;
  row-gap: 12px;
}

.button {
  max-width: 195px;

  @include desktopUltraHD {
    margin-top: 8px
  }
}

.text {
  @include F1_Regular_13pt;
  text-align: center;
  color: var(--text-dark-primary);

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }
}