
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.component {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid var(--background-dark-divider);

  &:first-child td {
    border-top: 1px solid var(--background-dark-divider);
  }

  @include desktopUltraHD {
    height: 53px;
  }
}

.hasAction {
  &:hover {
    cursor: pointer;
    background-color: var(--background-table-row);
  }
}
