
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  width: 100%;
}

.label {
  @include B2_Regular_13pt_20;
  display: block;
  margin-bottom: 8px;
  color: var(--text-dark-secondary);

  &Error {
    color: var(--text-dark-negative);
  }

  @include desktopFullHD {
    @include B3_Regular_14pt;
  }
}

.inputWrap {
  position: relative;
  width: 100%;
}

.input {
  @include B2_Regular_13pt_20;
  display: block;
  width: 100%;
  border-radius: 8px;
  background: var(--background-dark-inputs);
  border: 1px solid var(--background-dark-stroke);
  color: var(--text-dark-primary);

  &::placeholder {
    color: var(--text-dark-tertiary);
  }

  &:focus {
    border-color: var(--accent-dark-active-inverted);
  }

  &Error {
    border-color: var(--accent-dark-negative-inverted) !important;
  }

  @include desktopFullHD {
    @include B1_Regular_16pt;
  }
}

.small {
  padding: 4px 40px 4px 12px;

  @include desktopFullHD {
    padding: 5px 40px 5px 12px;
  }
}

.large {
  padding: 9px 40px 9px 12px;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    transform: scale(0.8);
  }

  @include desktopFullHD {
    svg {
      transform: scale(1);
    }
  }
}

.small.rightIconButton {
  padding: 0;
  position: absolute;
  width: 20px;
  height: 12px;
  top: 10px;
  right: 8px;

  @include desktopFullHD {
    width: 24px;
    height: 24px;
    top: 6px;
  }
}

.large.rightIconButton {
  padding: 0;
  position: absolute;
  top: 8px;
  right: 8px;

  @include desktopFullHD {
    top: 9px;
  }
}

.small.leftIconButton {
  padding: 0;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 4px;
  left: 8px;

  @include desktopFullHD {
    width: 24px;
    height: 24px;
    top: 6px;
    left: 12px;
  }
}

.large.leftIconButton {
  padding: 0;
  position: absolute;
  top: 7px;
  left: 9px;

  @include desktopFullHD {
    padding: 0;
    position: absolute;
    top: 8px;
    left: 12px;
  }
}

.hasLeftIcon {
  padding-left: 36px;

  @include desktopFullHD {
    padding-left: 44px;
  }
}

.hasRightIcon {
  padding-right: 44px;
}

.errorText {
  @include F1_Regular_12pt;
  margin-top: 8px;
  display: block;
  color: var(--text-dark-negative);
}

.secureFieldErrorIcon {
  path {
    fill: var(--accent-dark-negative-inverted);
  }
  g {
    fill: var(--accent-dark-negative-inverted);
  }
}
