
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.user {
  @include B3_Regular_14pt;
  color: var(--text-dark-primary);
  margin: 0 16px 0 8px;
  white-space: nowrap;
}
