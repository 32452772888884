
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.component {
  text-align: left;
  color: var(--text-dark-secondary);
  padding: 12px 12px;
  white-space: nowrap;

  &:first-child {
    padding-left: 0;
  }

  @include B2_Regular_12pt_20;

  @include desktopL {
    @include B2_Regular_13pt_20;
  }

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }

  @include desktopUltraHD {
    @include B1_Regular_16pt;
  }
}

.primary {
  padding: 12px 12px;

  @include desktopL {
    padding: 12px 24px;
  }
}

.secondary {
  padding: 6px 12px;

  @include desktopL {
    padding: 6px 24px;
  }
}
