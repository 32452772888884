
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 80vw;

  @include laptop {
    min-width: 1108px;
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 12px 0 24px 0;
  padding: 4px 0;
}

.input {
  @include laptop {
    min-width: 437px;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px 16px;
  height: calc(100vh - 250px);
  max-height: 571px;
  overflow: scroll;

  @include laptop {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

.contentEmpty {
  display: flex;
  grid-template-columns: 1fr;
  height: calc(100vh - 340px);
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include laptop {
    min-width: 1122px;
  }
}

.content::-webkit-scrollbar {
  width: 14px;
}

.content::-webkit-scrollbar-thumb {
  border: 5px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: var(--controls-dark-inactive-tabbar);
}

.content::-webkit-scrollbar-corner {
  background: transparent;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 15px;
}

.heading {
  @include S1_Regular_16pt;
  color: var(--text-dark-primary);
}

.empty {
  @include S1_Regular_16pt;
  color: var(--text-dark-primary);
}

.paginator {
  height: 32px;
}
