
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: grid;
  gap: 7px;
  height: 100%;

  @include laptop {
    gap: 10px;
  }

  @include desktopUltraHD {
    gap: 14px;
  }
}

.container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.four {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.nine {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.sixteen {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.card {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--background-dark-primary-elevated);
  border-radius: 12px;

  &_disabled {
    cursor: not-allowed;
  }

  @include desktop {
    border-radius: 14px;
  }

  @include desktopL {
    border-radius: 16px;
  }

  @include desktopUltraHD {
    border-radius: 20px;
  }
}

.select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-dark-primary-elevated);
  border-radius: 12px;
  height: 100%;

  @include desktop {
    border-radius: 14px;
  }

  @include desktopL {
    border-radius: 16px;
  }

  @include desktopUltraHD {
    border-radius: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 352px;
  row-gap: 12px;
}

.button {
  max-width: 195px;

  @include desktopUltraHD {
    margin-top: 8px;
  }
}

.text {
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: var(--text-dark-primary);

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }
}
