
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  width: 450px;
}

.text {
  @include S1_Regular_16pt;
  color: var(--text-dark-secondary);
  margin-bottom: 28px;
}

.actions {
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.input {
  margin-bottom: 24px;
}
