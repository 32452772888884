
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  padding: 0 18px 0 16px;
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 8px;
  cursor: pointer;

  &Active {
    background-color: var(--background-dark-accordion);
  }

  @include laptop {
    padding: 0 16px 9px 20px;
  }

  @include laptop {
    padding: 0 16px 0 12px;
  }

  @include desktop {
    grid-template-columns: 24px 1fr;
  }

  @include desktopL {
    padding: 0 20px 0 16px;
  }

  @include desktopFullHD {
    column-gap: 12px;
    padding: 0 20px 0 24px;
  }
}

.radioButton {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  @include desktopL {
    width: 24px;
    height: 24px;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.nameContainer {
  display: grid;
  grid-template-columns: 1fr 12px;
  column-gap: 12px;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 16px;
  color: var(--text-dark-primary);
}

.contentLast {
  display: flex;
  flex-direction: column;
}

.name {
  @include F1_Regular_12pt;
  color: var(--text-dark-primary);
  margin-bottom: 2px;
  word-break: break-word;
  max-width: 180px;
  font-weight: 700;

  @include desktop {
    @include B4_Regular_14pt;
    font-weight: 700;
  }

  @include desktopFullHD {
    @include S1_Bold_16pt;
    font-weight: 700;
  }

  @include desktopUltraHD {
    @include T4_Bold_20pt;
  }
}

.streamsCount {
  @include F1_Regular_11pt;
  color: var(--text-dark-secondary);
  margin-bottom: 12px;

  @include desktop {
    @include F1_Regular_12pt;
  }

  @include desktopUltraHD {
    @include B2_Regular_14pt;
  }
}

.streamsContainer {
  display: flex;
  gap: 8px;
  flex-direction: column;
  height: fit-content;
  padding: 6px 0px 0px 0px;
  margin-bottom: 24px;

  @include laptop {
    gap: 0;
    margin-bottom: 14px;
    padding: 6px 20px 0px 0px;
  }
}

.streamContainer {
  display: grid;
  grid-template-columns: 7px 1fr;
  gap: 14px;
  align-items: center;
  align-items: flex-start;
}

.streamIndicator {
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: var(--accent-dark-positive-inverted);
  margin-top: 6px;

  &Inactive {
    background-color: var(--accent-dark-negative-inverted);
  }

  @include laptop {
    width: 6px;
    height: 6px;
    border-radius: 6px;
  }
}

.streamName {
  @include B2_Regular_14pt;
  color: var(--text-dark-primary);

  @include laptop {
    font-size: 11px;
  }
}

.divider {
  border-top: 1px solid transparent;

  &IsVisible {
    border-top: 1px solid var(--background-dark-divider);
  }
}
