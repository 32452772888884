
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
}

.office {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 45%;
  border-radius: 12px;
  padding: 8px 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 7px;
  justify-content: space-between;

  @include laptop {
    padding: 16px 20px;
  }

  @include desktop {
    margin-bottom: 8px;
    border-radius: 14px;
    padding: 12px 20px 12px 20px;
  }

  @include desktopL {
    margin-bottom: 9px;
    border-radius: 16px;
    padding: 20px 20px 16px 20px;
  }

  @include desktopFullHD {
    padding: 32px 22px 20px 22px;
    margin-bottom: 10px;
    border-radius: 20px;
  }

  @include desktopUltraHD {
    padding: 36px 32px 24px 32px;
    margin-bottom: 13px;
  }
}

.button {
  @include desktop {
    width: 200px;
  }

  @include desktopUltraHD {
    width: 294px;
  }
}

.officeName {
  @include T4_Bold_16pt;
  color: var(--text-dark-primary);
  margin-bottom: 0px;

  @include laptop {
    line-height: 20px;
    @include T4_Bold_16pt;
  }

  @include desktop {
    @include T4_Bold_18pt;
  }

  @include desktopL {
    @include T4_Bold_20pt;
  }

  @include desktopUltraHD {
    @include T2_Bold_28pt;
  }
}

.officeLocation {
  @include F1_Regular_12pt;
  color: var(--text-dark-primary);
  margin-bottom: 12px;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;

  @include desktop {
    margin-bottom: 18px;
    max-width: 300px;
  }

  @include desktopUltraHD {
    @include B2_Regular_14pt;
  }
}

.metrics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px;
  height: 100%;

  @include tablet {
    gap: 7px;
  }

  @include desktop {
    gap: 8px;
  }
}

.employee {
  circle {
    fill: var(--accent-dark-positive-inverted);
  }
}

.guest {
  circle {
    fill: var(--accent-dark-marker-yellow);
  }
}

.contractor {
  circle {
    fill: var(--accent-dark-marker-pink);
  }
}

.unrecognized {
  circle {
    fill: var(--accent-dark-negative-inverted);
  }
}

.card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: var(--background-dark-filter);
  border-radius: 12px;
  cursor: pointer;
  justify-content: center;
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  justify-content: space-between;

  @include tablet {
    padding: 12px 16px 8px 16px;
  }

  @include laptop {
    padding: 12px 16px;
  }

  &:hover {
    background-color: var(--background-dark-contex-hover);
  }

  &__icon {
    display: flex;
    height: 8px;
    align-items: center;
    margin-bottom: 13px;

    @include tablet {
      height: 20px;
    }

    @include desktopUltraHD {
      height: 24px;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 12px;
    font-family: 'Aeroport', sans-serif;
    font-style: normal;
    line-height: 14px;
    color: var(--text-dark-secondary);
    margin-bottom: 2px;

    @include desktop {
      border-radius: 14px;
    }

    @include desktopL {
      font-size: 13px;
      line-height: 16px;
    }

    @include desktopFullHD {
      @include B2_Regular_14pt;
    }

    @include desktopUltraHD {
      @include S1_Regular_16pt;
    }
  }

  @include desktop {
    padding: 16px 20px 12px 20px;
    border-radius: 14px;
  }

  @include desktopL {
    border-radius: 16px;
  }

  @include desktopFullHD {
    padding: 16px 24px 16px 24px;
    border-radius: 20px;
  }

  @include desktopUltraHD {
    padding: 20px 28px 20px 28px;
  }
}

.counterContainer {
  display: flex;
  align-items: baseline;
}

.counter {
  color: var(--text-dark-primary);
  margin-right: 5px;
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  @include desktopL {
    @include T4_Bold_20pt;
  }

  @include desktopUltraHD {
    @include T2_Bold_28pt;
  }
}

.counterMax {
  color: var(--text-dark-primary);
  white-space: nowrap;
  font-family: 'Aeroport', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }

  @include desktopUltraHD {
    @include B1_Regular_16pt;
  }
}
