
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(18px);
  z-index: 9999;
  display: flex;
  align-items: center;

  @include tablet{
    backdrop-filter: brightness(60%);
  }
}

.container {
  position: relative;
  overflow-y: auto;
  height: 100%;
  width: 300px;
}

.background {
  background: var(--background-dark-overlay);
  backdrop-filter: blur(15px);
  flex: 1;
  height: 100%;
  order: 1;
}

.container::-webkit-scrollbar {
  width: 0px;
}

.left {
  order: 0;
}

.right {
  order: 2;
}

.inner {
  min-width: 448px;
  position: relative;
  border-radius: 16px;
  padding: 24px;
  overflow-y: auto;
}

.headerContainer {
  display: grid;
  grid-template-columns: 1fr 32px;
  column-gap: 16px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 12px;
}

.title {
  @include T1_Regular_32pt;
  color: var(--text-dark-primary);
  word-break: break-word;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 0;
  border: none;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 17px;
  right: 8px;

  @include tablet {
    top: 24px;
    align-items: flex-start;
  }
}
