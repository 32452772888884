
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.app {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: var(--background-dark-primary);
  padding: 0 24px 18px 24px;

  @include laptop {
    padding: 0 28px 20px 28px;
  }

  @include desktop {
    padding: 0 30px 22px 30px;
  }

  @include desktopL {
    padding: 0 36px 24px 36px;
  }

  @include desktopFullHD {
    padding: 0 40px 32px 40px;
  }

  @include desktopUltraHD {
    padding: 0 53px 34px 53px;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.root {
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.dashboard {
  height: calc(100vh - 116px);
  width: 100%;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.primaryDashboard {
  padding: 24px;
  background-color: var(--background-dark-primary-elevated);
}

.secondaryDashboard {
  padding: 0;
  background-color: var(--background-dark-primary);
}
