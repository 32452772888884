
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  @include desktopL {
    width: 24px;
    height: 24px;
  }

  &Initial {
    border: 1.5px solid var(--controls-dark-tertiary-active);
    background-color: var(--background-button-active);
  }

  &Checked {
    border: none;
    background-color: var(--controls-dark-primary-active);
  }
}

.active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2px;
  height: 2px;
  border-radius: 50%;

  &Checked {
    background-color: var(--background-dark-primary-elevated);
  }
}

.hidden {
  position: absolute;
  cursor: pointer;
  opacity: 0;

  &:disabled {
    cursor: not-allowed;
  }
}

.checkbox {
  margin: 2px;
  width: 15px;
  height: 15px;

  @include desktopL {
    margin: 3px;
    width: 18px;
    height: 18px;
  }
}
