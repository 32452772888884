
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  flex-direction: column;
  padding: 12px 20px 20px 16px;

  &Active {
    background-color: var(--background-dark-accordion);
  }

  @include desktopL {
    padding: 16px 20px 16px 20px;
  }

  @include desktopFullHD {
    padding: 20px 24px 20px 24px;
  }
}

.header {
  display: grid;
  grid-template-columns: 20px 1fr 12px;
  column-gap: 16px;
  cursor: pointer;

  @include desktopL {
    grid-template-columns: 24px 1fr 12px;
  }

  @include desktopUltraHD {
    grid-template-columns: 32px 1fr 12px;
  }

  @include laptop {
    & svg {
      margin-top: 6px;
    }
  }
}

.headingConteiner {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.heading {
  @include S1_Bold_15pt;
  color: var(--text-dark-primary);
  word-break: break-word;

  @include desktopL {
    @include S1_Bold_16pt;
  }

  @include desktopUltraHD {
    @include T4_Bold_20pt;
  }
}

.timeshamp {
  @include F1_Regular_12pt;
  color: var(--text-dark-secondary);

  @include desktopUltraHD {
    @include B2_Regular_14pt;
  }
}

.content {
  margin-top: 10px;
  margin-left: 40px;

  @include desktopL {
    margin-left: 42px;
  }

  @include desktopUltraHD {
    margin-left: 48px;
  }
}

.text {
  @include F1_Regular_12pt;
  color: var(--text-dark-primary);
  margin-right: 12px;

  @include desktopL {
    @include F1_Regular_13pt;
  }

  @include desktopFullHD {
    @include B2_Regular_14pt;
  }

  @include desktopUltraHD {
    @include S1_Regular_16pt;
  }
}

.actions {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
  padding-right: 12px;
}
