
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.button {
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 20px;
  color: var(--text-dark-primary);
  transition: all 0.1s;

  &:hover:not(:disabled) {
    cursor: pointer;
  }

  &:focus:not(:disabled) {
    outline: none;
  }

  &:active:not(:disabled) {
    transform: scale(0.99);
  }
}

.disabled {
  color: var(--text-dark-tertiary);
  background-color: var(--controls-dark-inactive) !important;
  cursor: not-allowed;
}

.small {
  @include F3_Regular_12pt;
  padding: 4px 8px 8px;

  @include laptop {
    padding: 4px 6px 6px;
    @include F3_Regular_12pt;
  }

  @include desktopL {
    padding: 5px 11px 7px;
    @include F4_Regular_13pt;
  }

  @include desktopFullHD {
    padding: 5px 16px 7px;
    @include B2_Regular_14pt;
  }

  @include desktopUltraHD {
    padding: 11px 24px 11px 24px;
    @include B1_Regular_16pt;
    line-height: 21px;
  }
}

.large {
  @include S1_Regular_16pt;

  padding-top: 10px;
  padding-bottom: 13px;
}

.primary {
  background-color: var(--controls-dark-primary-active);

  &:hover {
    background-color: var(--controls-dark-primary-light);
  }
}

.secondary {
  background-color: var(--background-dark-stroke);

  &:hover {
    background-color: var(--controls-dark-tertiary-light);
  }
}

.ghost {
  background-color: transparent;

  &:hover {
    background-color: var(--controls-dark-tertiary-active);
  }
}

.spinner {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.leftIcon {
  margin-right: 5px;

  &_disabled {
    svg > g {
      stroke: var(--text-dark-tertiary);
    }
  }
}
