
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.root {
  display: flex;
  margin-bottom: 9px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.title {
  @include T3_Regular_24pt;
  color: var(--text-dark-primary);
  margin-bottom: 20px;
  white-space: nowrap;

  @include desktopL {
    @include T2_Regular_28pt;
  }

  @include desktopFullHD {
    @include T1_Regular_32pt;
  }
}

.nav {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}
