
          @import "src/shared/styles/variables.scss";
          @import "src/shared/styles/mixins.scss";
          @import "src/shared/styles/typography.scss";
        
.component {
  position: relative;
  width: 100%;
  background-color: var(--background-dark-primary-elevated);
}

.primary {
  background-color: var(--background-dark-primary-elevated);
}

.secondary {
  background-color: var(--background-dark-accordion);
}

.sticky {
  top: 0;
  position: sticky;
  border: none;
  transform: translateY(-1px);
}

.bigRow {
  height: 44px;
}

.smallRow {
  height: 32px;
}
